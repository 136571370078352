import React from 'react'
import { navigate } from 'gatsby-link'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { emailRegexp } from '../utils/constants'
import SideBar from '../components/SideBar'
import ContactModal from '../components/ContactModal'
import { HTMLContent } from '../components/Content'

import {
  ContactForm,
  SubmitButton,
  StyledInput,
  ErrorMessage,
  StyledTextArea,
  FormContainer,
  HomeTitle,
  ContactInfo,
} from '../styles'

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: {
        show: false,
        err: '',
      },
      nameValid: false,
      emailValid: false,
      messageValid: false,
      isValidated: false,
      formErrors: { name: '', email: '', message: '' },
    }
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  validateField = (filedName, fieldValue) => {
    let { formErrors, emailValid, nameValid, messageValid } = this.state

    switch (filedName) {
      case 'email':
        emailValid = emailRegexp.test(fieldValue)
        formErrors.email = emailValid ? '' : 'Email is invalid'
        break
      case 'name':
        nameValid = fieldValue.length > 0
        formErrors.name = nameValid ? '' : 'Name is too short'
        break
      case 'message':
        messageValid = fieldValue.length > 0
        formErrors.message = messageValid ? '' : 'Comments is too short'
        break
      default:
        break
    }

    this.setState(
      {
        formErrors: formErrors,
        emailValid: emailValid,
        nameValid: nameValid,
        messageValid: messageValid,
      },
      this.validateForm
    )
  }

  validateForm = () => {
    let { emailValid, nameValid, messageValid } = this.state
    this.setState({
      isValidated: emailValid && nameValid && messageValid,
    })
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value }, () =>
      this.validateField(name, value.trim())
    )
  }

  handleResponse = (error = '') => {
    this.setState({
      modal: {
        show: true,
        err: error,
      },
    })
  }

  handleRedirect = (error = '') => {
    error ? this.setState({ modal: { show: false, err: '' } }) : navigate('/')
  }

  showError = error => {
    return !(error.length === 0)
  }

  handleSubmit = e => {
    const form = e.target

    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.handleResponse())
      .catch(error => this.handleResponse(error))
  }

  render() {
    let { isValidated, modal } = this.state
    let { name, email, message } = this.state.formErrors

    return (
      <Layout removeFooter={true}>
        <ContactModal
          isOpen={modal.show}
          error={modal.err}
          onClose={() => this.handleRedirect(modal.err)}
        />
        <section className="section">
          <div className="container" css={FormContainer}>
            <HomeTitle>
              <h3>Path.Money</h3>
            </HomeTitle>
            <SideBar />
            <ContactForm
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              autoComplete="off"
            >
              <ContactInfo>
                <HTMLContent content={this.props.data.markdownRemark.html} />
              </ContactInfo>
              <h3>Contact us</h3>
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <div className="field">
                <label className="label" htmlFor={'name'}>
                  Name:
                </label>
                <div className="control">
                  <StyledInput
                    error={this.showError(name)}
                    className="input"
                    name={'name'}
                    onChange={this.handleChange}
                    id={'name'}
                    placeholder="Enter your name..."
                  />
                  {this.showError(name) ? (
                    <ErrorMessage>{name}</ErrorMessage>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  Email Address:
                </label>
                <div className="control">
                  <StyledInput
                    error={this.showError(email)}
                    className="input"
                    name={'email'}
                    onChange={this.handleChange}
                    id={'email'}
                    placeholder="name@example.com"
                  />
                  {this.showError(email) ? (
                    <ErrorMessage>{email}</ErrorMessage>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'message'}>
                  Comments:
                </label>
                <div className="control">
                  <StyledTextArea
                    error={this.showError(message)}
                    className="textarea"
                    name={'message'}
                    onChange={this.handleChange}
                    id={'message'}
                    placeholder="Enter your comments..."
                    rows="5"
                  />
                  {this.showError(message) ? (
                    <ErrorMessage>{message}</ErrorMessage>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <SubmitButton
                  disabled={!isValidated}
                  className="button is-link"
                  type="submit"
                >
                  Submit form
                </SubmitButton>
              </div>
            </ContactForm>
          </div>
        </section>
      </Layout>
    )
  }
}

export const contactPageQuery = graphql`
    query ContactPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
            }
        }
    }
`